.app__controls {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.app__controls__user span {
    background: var(--color-white);
    border-radius: 0 0 0 var(--radius-medium);
    box-shadow: var(--shadow);
    color: var(--color-base);
    display: block;
    font-size: .8125rem;
    font-weight: 300;
    height: 100%;
    line-height: 1.5;
    padding: .75rem 2em .75rem 1.5em;
    text-decoration: none;
}

.app__controls__user span:active, .app__controls__user span:focus, .app__controls__user span:hover {
    background: var(--color-base);
    color: var(--color-white);
}

.app__controls__user span strong {
    color: var(--color-main-1);
    font-size: 1.27em;
    font-weight: 400;
    line-height: 1.1;
    transition: all .3s var(--transition);
}

.app__controls__user span:active strong, .app__controls__user span:focus strong, .app__controls__user span:hover strong {
    color: var(--color-white);
}

.app__controls__touch {
    display: none;
}

.app__controls__menu {
    position: relative;
    z-index: 9999;
}

.app__controls__menu button {
    align-items: center;
    background: var(--color-aside-1);
    background: var(--gradient-2);
    border: none;
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-width: 4rem;
    outline: none;
    padding: .25em .75em;
    position: relative;
    transition: all .3s var(--transition);
    z-index: 2;
}

.app__controls__menu button:active, .app__controls__menu button:focus, .app__controls__menu button:hover {
    background: var(--color-main-3);
}

.app__controls__menu button.open {
    background: var(--color-aside-3);
}

.app__controls__menu .burger {
    height: 22px;
    position: relative;
    width: 75%;
}

.app__controls__menu .burger span {
    background: var(--color-white);
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    top: calc(50% - 1px);
    transition: background .2s var(--transition);
}

.app__controls__menu .open .burger span {
    background: none;
    left: -3px;
}

.app__controls__menu .burger span::before, .app__controls__menu .burger span::after {
    background: var(--color-white);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    transition-delay: .3s, 0s, 0s;
    transition-duration: .3s, .3s, .2s;
}

.app__controls__menu .burger span::before {
    right: 0;
    top: -6px;
    transition-property: top, transform, background;
}

.app__controls__menu .open .burger span::before {
    left: .625rem;
    top: 0;
    transform: rotate(45deg);
    width: 20px;
}

.app__controls__menu .burger span::after {
    bottom: -6px;
    right: 8px;
    transition-property: bottom, transform, background;
}

.app__controls__menu .open .burger span::after {
    bottom: 0;
    left: .625rem;
    transform: rotate(-45deg);
    width: 20px;
}

.app__controls__menu .stamp {
    font-size: .625rem;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}

.app__controls__touch svg {
    fill: transparent;
    height: 1.6rem;
    stroke-width: 5;
    stroke: var(--color-white);
    transition: all .3s var(--transition);
}

@media only screen and (min-width: 80rem) {
    .app__controls__user span {
        font-size: .94rem;
        padding: 2rem 5em 2rem 2.5em;
    }

    .app__controls__touch {
        background: var(--color-main-2);
        border-radius: 0 0 0 var(--radius-medium);
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-left: calc(-1 * var(--radius-medium));
        padding: 1rem;
    }

    .app__controls__touch svg {
        fill: transparent;
        height: 3.3rem;
        stroke: var(--color-white);
        transition: all .3s var(--transition);
    }

    .app__controls__menu {
        display: none;
    }
}