.ProjectContainer {
    margin-top: 48px;
    padding: 24px 32px;
    background-color: #ffffff;
    box-shadow: -6px 6px 20px 0 rgb(14 30 37 / 20%);
    border-radius: 16px;
}

div.ProjectContainer:last-child {
    margin-top: 26px;
}

.ProjectContainer .formContent {
    display: flex;
}

.ProjectContainer .formContent form {
    width: 100%;
}

.ProjectContainer .formActions {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.ProjectContainer .formActions > div button {
    margin-right: 1rem;
}

.ProjectContainer .formContent form > .customGrid,
.ProjectContainer .formContent form .grid > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 16px;
    margin-bottom: 16px;
}

.ProjectContainer .formContent .colorSettings > label {
    font-size: 26px;
}

.ProjectContainer .FormCheckbox input {
    margin-top: .5rem;
}

.ProjectContainer .FormCheckbox {
    text-transform: uppercase;
    margin-bottom: auto;
}

.ProjectContainer .scheduledTask label:first-child {
    width: 12rem;
}

.ProjectContainer .scheduledTask label:nth-child(2) {
    width: 3rem;
}

.ReactModal__Overlay--after-open {
    z-index: 100;
}

.ProjectContainer .validation-error {
    color: red;
    text-align: center;
}

.ProjectContainer h4 {
    margin-top: 3rem;
    display: flex;
    align-items: center;
}

.ProjectContainer h4, .ProjectContainer h3 {
    color: var(--color-main-2);
}

.ProjectContainer .divider {
    margin: 1rem 0;
}

.logContainer label {
    cursor: auto;
    color: white;
    line-height: 1.42857143;
}

.logContainer {
    margin-top: 4rem;
    padding: 2rem 4rem;
    max-height: 600px;
    overflow-y: scroll;
    font-family: "Source Sans Pro", Arial, sans-serif;
    background-color: #4b4f5c;
}

.ProjectContainer .download-icon {
    width: 40px;
    height: 40px;
    margin-left: 1rem;
    cursor: pointer;
}

.ProjectContainer .FormText.url-conflict {

}

.ProjectContainer .dkimKeys > div{
    width: 100%;
}

.ProjectContainer .dkimKeys {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    text-align: left;
}