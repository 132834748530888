
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

:root {
	--color-disabled: rgba(1, 1, 1, .05);
	--color-base: #1b2338;
	--color-black: #000;
	--color-rgb-black: 0, 0, 0;
	--color-gray: #dadada;
	--color-main-1: #141436;
	--color-main-2: #01A2D8;
	--color-main-3: #B9BCC3;
	--color-aside-1: #89A514;
	--color-aside-2: #B8D73E;
	--color-aside-3: #f8433f;
	--color-light: #fefefe;
	--color-slate: #b7bac1;
	--color-white: #fff;
	--color-rgb-white: 255, 255, 255;

	--element: 3rem;

	--font-main: 'Gotham', Verdana, Arial;

	--gradient-1: linear-gradient(158deg, var(--color-main-1), var(--color-main-2));
	--gradient-2: linear-gradient(90deg, var(--color-aside-1), var(--color-aside-2));

	--img-cross: url(../../img/360-cross.svg) no-repeat;
	--img-target: url(../../img/svg/ico-map.svg) no-repeat;

	--page-width-max: 128rem;

	--radius-small: .25rem;
	--radius-medium: .5rem;
	--radius-large: 1.6rem;
	--radius-giant: 3rem;

	--shadow: 0 0 50px rgba(var(--color-rgb-black), .08);

	--transition: ease-in;

	accent-color: var(--color-main-2);
	font-size: 14px;
}

::selection {
	background: var(--color-aside-1);
	color: var(--color-white);
}

article, aside, figure, footer, header, main, nav, section {
	display: block;
}

img, video {
	height: auto;
	max-width: 100%;
}

@media only screen and (min-width: 40rem) {
	:root {
		--element: 3.6rem;

		font-size: 16px;
	}
}