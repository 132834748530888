.OverviewTable .cross {
    fill: var(--color-aside-2);
}

.OverviewTable .action {
    text-align: right;
}

.app__table__actions {
    padding: 1rem 1.125rem;
}

.OverviewTable td a {
    font-weight: 400;
}

.OverviewTable .sort {
    text-decoration: underline;
    cursor: pointer;
}

.OverviewTable .orderBy {
    font-size: 24px;
    line-height: 1;
    color: green;
}