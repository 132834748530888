.FormCheckbox {
    display: inline-flex;
    align-items: center;
}

.FormCheckbox input {
    margin-right: 1rem;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.FormCheckbox label {
    line-height: 1;
    padding-bottom: 0;
}