.StartProdModalContent .domain-row {
    display: flex;
    justify-content: space-between;
}

.StartProdModalContent,
.StartProdModalContent .body {
    height: 100%;
    padding: 1rem;
}

.ReactModal__Content--after-open,
.StartProdModalContent {
    display: flex;
    flex-direction: column;
}

.StartProdModalContent .footer {
    justify-content: space-between;
    display: flex;
}