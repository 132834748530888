.app {
    min-height: 100vh;
    overflow: hidden;
}

.app__body {
    background: var(--color-light);
}

.app__main {
    padding: 0 1.5rem 1.5rem 1.5rem;
}

.app__footer {
    margin-top: 2.5rem;
}

.logo {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.logo img,
.logo svg {
    display: block;
    max-height: 7rem;
    margin-bottom: .4rem;
    width: auto;
    height: auto;
}

.logo svg {
    display: block;
    height: 4.4rem;
    margin-bottom: .4rem;
    width: auto;
}

.logo span {
    display: inline-block;
    font-size: .6rem;
    font-weight: 700;
    letter-spacing: -.01em;
    line-height: 1;
    text-transform: uppercase;
}

@media only screen and (min-width: 80rem) {
    .app {
        display: flex;
    }

    .app__body {
        width: calc(100% - 24rem);
    }

    .app__main {
        padding: 0 2.5rem 1.5rem 2.5rem;
    }
}

@media only screen and (min-width: 100rem) {
    .app__main {
        padding: 0 5rem 1.5rem 5rem;
    }
}