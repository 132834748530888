.app__navbar {
    background: linear-gradient(180deg, #141436, #25254B);
    bottom: 0;
    color: var(--color-white);
    opacity: 0;
    overflow: hidden;
    padding: 5.5rem 2rem 2.5rem 2rem;
    pointer-events: none;
    position: fixed;
    top: 0;
    transform: translateY(-100%);
    transition: transform .4s var(--transition);
    width: 100%;
    z-index: 10;
}

.app__navbar.open {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
}

.app__navbar .logo {
    align-items: center;
    margin-bottom: 2.75rem;
}

.app__nav ul {
    list-style: none;
    margin: 0;
}

.app__nav li + li {
    margin-top: .7em;
}

.app__nav a {
    background: linear-gradient(90deg, #25254B, #25254B);
    border-radius: var(--radius-giant);
    color: var(--color-white);
    display: block;
    padding: 1em 2.5em 1em 5.2em;
    position: relative;
    text-decoration: none;
}

.app__nav a:active, .app__nav a:focus, .app__nav a:hover {
    background: var(--color-white);
    color: var(--color-main-1);
}

.app__nav a.selected {
    background: var(--color-light);
    color: var(--color-main-1);
}

.app__nav a svg {
    fill: var(--color-main-2);
    height: 3rem;
    left: 1.75rem;
    position: absolute;
    top: calc(50% - 1.5rem);
    width: 2rem;
}

@media only screen and (min-width: 80rem) {
    .app__nav a {
        border-radius: var(--radius-giant) 0 0 var(--radius-giant);
    }

    .app__navbar {
        --coef: 2;
        --cross-size: 670px;
        --target-size: 800px;
        opacity: 1;
        padding: 6rem 0 2rem 3rem;
        pointer-events: all;
        position: relative;
        transform: translateY(0);
        width: 24rem;
    }

    .app__navbar::before {
        background-size: cover;
        content: '';
        pointer-events: none;
        position: absolute;
        z-index: -1;
        background: var(--img-target);
        bottom: calc(-1 * (var(--target-size) / var(--coef)));
        height: var(--target-size);
        width: var(--target-size);
        transform: rotate(270deg) translateY(-180px) translateX(130px);
        opacity: .3;
    }

    .app__navbar .logo {
        margin-bottom: 5.75rem;
        padding-right: 3rem;
    }
}