.AppConfigForm .formContent {
    margin-bottom: 4rem;
}

.AppConfigForm {
    padding: 2rem;
}

.AppConfigForm .formActions {
    display: flex;
    justify-content: space-between;
}