.page {
    box-shadow: 0 0 15px 5px rgba(var(--color-rgb-black), .125);
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: var(--page-width-max);
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    text-align: left;
    width: 100%;
}

.logo--color span {
    color: var(--color-main-2);
}

.logo--white span {
    color: var(--color-white);
}

.divider {
    background: var(--color-main-2);
    height: 1px;
    margin: 2em 0;
}

[class^="col-"] + [class^="col-"] {
    margin-top: 1.5rem;
}

@media only screen and (min-width: 40rem) {
    .row {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
    }

    [class^="col-"] + [class^="col-"] {
        margin-top: 0;
    }

    .col-50 {
        width: calc(50% - 1rem);
    }

    .col-25 {
        width: calc(25% - 1.5rem);
    }
}